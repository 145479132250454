<template>
  <!-- 编辑数字通证管理 -->
  <div>
    <div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" v-loading="loading">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="数字通证名称:" prop="tokenName">
              <el-input v-model="ruleForm.tokenName" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数字通证英文名称:" prop="tokenEnglish">
              <el-input v-model="ruleForm.tokenEnglish" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数字通证总量:" prop="gross">
              <el-input v-model="ruleForm.gross" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区块时间:" prop="blocksTime">
              <el-input v-model="ruleForm.blocksTime" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开发者:" prop="developer">
              <el-input v-model="ruleForm.developer" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="核心算法:" prop="coreAlgorithm">
              <el-input v-model="ruleForm.coreAlgorithm" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发行时间:" prop="issuingDate">
              <el-date-picker
                v-model="ruleForm.issuingDate"
                type="date"
                placeholder="选择日期"
                style="width: 365px"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预上线时间:" prop="predictOnlineTime">
              <el-date-picker
                v-model="ruleForm.predictOnlineTime"
                type="datetime"
                placeholder="选择日期时间"
                style="width: 365px"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上线时间:" prop="online_time">
              <el-date-picker
                v-model="ruleForm.online_time"
                :picker-options="ruleForm.online_tim"
                type="datetime"
                placeholder="选择日期时间"
                style="width: 365px"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上线价格:" prop="price">
              <el-input v-model="ruleForm.price" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结算价格:" prop="price">
              <el-input
                v-model="ruleForm.settlementPrice"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="涨跌幅限额:" prop="price">
              <el-input
                v-model="ruleForm.rate"
                placeholder=""
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="交易所数字通证地址:" prop="tokenAddress">
              <el-radio-group v-model="ruleForm.tokenAddress">
                <el-radio label="1">显示</el-radio>
                <el-radio label="2">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="钱包地址:" prop="moneyAddress">
              <el-radio-group v-model="ruleForm.moneyAddress">
                <el-radio label="1">显示</el-radio>
                <el-radio label="2">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="结算开启:" prop="moneyAddress">
              <el-radio-group v-model="ruleForm.accountStatus">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="2">不显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选择分区类别" prop="typeId">
              <el-select v-model="ruleForm.typeId" placeholder="请选择活动区域">
                <el-option v-for="(item, index) in selectBox" :key="index" :label="item.typeName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通证编号" prop="tokenNum">
              <el-input v-model="ruleForm.tokenNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序值" prop="tokenNum">
              <el-input v-model="ruleForm.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传图片:">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :http-request="mainPicUpload"
              >
                <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="数字通行证介绍:" prop="introduce">
              <el-input type="textarea" :rows="4" placeholder="" v-model="ruleForm.introduce"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      id: null,
      ruleForm: {},
      imageUrl: '',
      // 验证规则
      rules: {
        tokenName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        tokenEnglish: [{ required: true, message: '请输入英文名', trigger: 'blur' }],
        gross: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        blocksTime: [{ required: true, message: '请输入区块时间', trigger: 'blur' }],
        developer: [{ required: true, message: '请输入开发者', trigger: 'blur' }],
        coreAlgorithm: [{ required: true, message: '请输入核心算法', trigger: 'blur' }],

        issuingDate: [
          {
            type: 'date',
            required: true,
            message: '请选择发行时间',
            trigger: 'change'
          }
        ],
        predictOnlineTime: [
          {
            type: 'date',
            required: true,
            message: '请选择预计上线时间',
            trigger: 'change'
          }
        ],
        online_time: [
          {
            type: 'date',
            required: true,
            message: '请选择上线时间',
            trigger: 'change'
          }
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        introduce: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择分区类别', trigger: 'change' }],
        tokenNum: [{ required: true, message: '请输入排序字段', trigger: 'blur' }]
      },
      selectBox: '' //选择框
    }
  },
  created() {
    console.log(this.$route.query.id)
    this.id = this.$route.query.id
    // 获取数字通证详情
    this.axios.get('/admin/certificate/findById', { id: this.id }).then(res => {
      console.log(res.data.data)
      console.log()
      if (res.data.code == 200) {
        this.ruleForm = res.data.data
        this.ruleForm.online_time = Date.parse(new Date(res.data.data.predictOnlineTime))
        this.ruleForm.issuingDate = Date.parse(new Date(res.data.data.issuingDate))
        this.ruleForm.predictOnlineTime = Date.parse(new Date(res.data.data.predictOnlineTime))
      }
    })

    // 获取下拉框
    this.axios.get('/admin/certificateType/findAll', {}).then(res => {
      if (res.data.code == 200) {
        this.selectBox = res.data.data
      }
    })
  },
  methods: {
    // 上传图片
    mainPicUpload(options) {
      this.loading = true
      function dataUrl(data) {
        let arr = data.split('&')
        let list = {}
        arr.forEach(item => {
          let itm = item.split('=')
          list[itm[0]] = decodeURIComponent(itm[1])
        })
        return list
      }
      var { file } = options
      let data
      this.axios.get('/getOSSKey', {}).then(res => {
        data = res.data.data
        var ossData = new FormData()
        ossData.append('name', file.name)
        let filename = `${Date.parse(new Date())}` + file.name //图片名称
        let keyValue = data.dir + filename
        //请求数据
        ossData.append('key', keyValue)
        ossData.append('callback', data.callback)
        ossData.append('policy', data.policy)
        ossData.append('OSSAccessKeyId', data.accessid)
        ossData.append('success_action_status', 200)
        ossData.append('signature', data.signature)
        ossData.append('file', file)
        this.axios.post(data.host, ossData).then(res => {
          this.imageUrl = dataUrl(res.data.Status)
          this.imageUrl = this.imageUrl.filename
          this.ruleForm.image = this.imageUrl
          this.loading = false
        })
      })
    },

    handleAvatarSuccess(res, file) {
      console.log(res, file)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    // 点击更新按钮
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.axios
            .put('/admin/certificate/updateCertificate', this.ruleForm)
            .then(res => {
              console.log(res)
              if (res.code == 200) {
                this.$message({
                  message: '保存成功!',
                  type: 'success'
                })
                this.$router.go(-1)
              }
            })
            .catch(err => {
              this.$message({
                message: '更新失败！',
                type: 'error'
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  padding: 50px;
  width: 1000px;
  display: flex;
  // background-color: red;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #8c939d;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 5px;
}
</style>
